:root {
  --fs-76: 4.77rem;
  --fs-60: 3.75rem;
  --fs-48: 3rem;
  --fs-38: 2.4rem;
  --fs-32: 2rem;
  --fs-24: 1.5rem;
  --fs-20: 1.25rem;
  --fs-16: 1rem;

  --primary-color: #fff;
  --secondary-color: #2f3848;
  --accent-color: #31809d;
  --accent-color2: #a1a1a1;
  --bg-color: #12121c;

  --padding-nav: 20px 155px;
  --container: 112.5rem;
}

@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  box-sizing: border-box;
  z-index: 2;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  color: #fff;
  font-family: "Outfit", sans-serif;
  background-color: #12121c;
}

.app {
  background-color: var(--bg-color);
}

#light {
  --bg-color: #fff;
  --primary-color: #12121c;
  color: #12121c;
}

#light .header {
  background-color: #fff;
}

#light a {
  color: #12121c;
  font-weight: 500;
}

#light a:hover {
  color: var(--accent-color);
}

.wrapper {
  position: relative;
  width: calc(100% - 19.4rem);
  max-width: var(--container);
  margin-inline: auto;
}

/* 1500px */

@media screen and (max-width: 96.8em) {
  :root {
    --padding-nav: 20px 95px;
  }

  .wrapper {
    width: calc(100% - 11.8rem);
  }
}

/* 1100px */

@media screen and (max-width: 68.75em) {
  :root {
    --padding-nav: 20px 55px;
  }

  .wrapper {
    width: calc(100% - 5.6rem);
  }
}

/* 600px */

@media screen and (max-width: 37.5em) {
  :root {
    --padding-nav: 20px 35px;
  }

  .wrapper {
    width: calc(100% - 2.5rem);
  }
}

a {
  color: #fff;
  font-family: "Outfit", sans-serif;
  text-decoration: none;
}

#light button button {
  color: #fff;
  font-family: "Outfit", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  overflow: hidden;
}

.logo {
  transition: ease 0.3s;
  display: flex;
  align-items: center;
}

.logo:hover {
  color: var(--accent-color2);
}

.logo img {
  width: 50px;
  height: 100%;
  margin-right: 20px;
  transition: linear 0.3s;
}

.logo span {
  opacity: 1;
  transition: ease 0.2s;
}

.logo--active span {
  opacity: 0;
  pointer-events: none;
}

.logo--active img {
  width: 70px;
  height: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px #31809d2c inset;
  transition: background-color 5000s ease-in-out 0s;
}

.app-cursor {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #31809d;
  border: 0px solid #31809d;
  position: fixed;
  z-index: 1000;
  pointer-events: none;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  transition: transform 0.15s, width 0.6s, height 0.6s, background-color 0.6s;
}

.app-cursor2 {
  width: 50px;
  height: 50px;
  opacity: 0.5;
  border: 2px solid #31809d;
  border-radius: 50%;
  background-color: transparent;
  position: fixed;
  z-index: 1000;
  pointer-events: none;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  transition: transform 0.15s, width 0.2s, height 0.2s, background-color 0.2s;
}

@property --rotate {
  syntax: "<angle>";
  initial-value: 132deg;
  inherits: false;
}

/* TYPOGRAPHY STYLES */

.menu-font {
  font-size: var(--fs-16);
  font-weight: 300;
  z-index: 5;
}

.xxs {
  font-size: var(--fs-16);
  font-weight: 300;
  z-index: 5;
}

.xs {
  font-size: var(--fs-20);
  font-weight: 300;
  color: #a1a1a1;
  line-height: 110%;
  letter-spacing: 0.8px;
  z-index: 5;
}

.s {
  font-size: var(--fs-24);
  font-weight: 500;
  z-index: 5;
}

.m {
  font-size: var(--fs-32);
  font-weight: 400;
  z-index: 5;
}

.l {
  font-size: var(--fs-38);
  font-weight: 500;
  z-index: 5;
}

.xl {
  font-size: var(--fs-48);
  font-weight: 700;
  line-height: var(--fs-48);
  z-index: 5;
  position: relative;
}
.xxl {
  font-size: var(--fs-60);
  font-weight: 800;
  z-index: 5;
}
.xxxl {
  font-size: var(--fs-76);
  font-weight: 800;
  z-index: 5;
}

.type {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  line-height: 5.2rem;
}

@media screen and (max-width: 600px) {
  .xxs {
    font-size: var(--fs-16);
    font-weight: 300;
    z-index: 5;
  }

  .xs {
    font-size: var(--fs-20);
    font-weight: 300;
    color: #a1a1a1;
    line-height: 110%;
    letter-spacing: 0.8px;
    z-index: 5;
  }

  .s {
    font-size: var(--fs-24);
    font-weight: 500;
    z-index: 5;
  }

  .m {
    font-size: var(--fs-24);
    font-weight: 400;
    z-index: 5;
  }

  .l {
    font-size: var(--fs-38);
    font-weight: 500;
    z-index: 5;
  }

  .xl {
    font-size: var(--fs-48);
    font-weight: 700;
    z-index: 5;
    position: relative;
  }
  .xxl {
    font-size: var(--fs-60);
    font-weight: 800;
    z-index: 5;
  }
  .xxxl {
    font-size: var(--fs-48);
    font-weight: 900;
    line-height: var(--fs-48);
    z-index: 5;
  }
}

@media screen and (max-width: 1100px) {
  .menu_text .line .word {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    line-height: 3rem;
  }

  .menu_chars {
    transform: translateY(100%);
    transition: transform 0.5s;
  }
}

/* Flex class */

.grid {
  display: grid;
}

.flex {
  display: flex;
}

.direction-c {
  flex-direction: column;
}

.jc-sb {
  justify-content: space-between;
}

.jc-c {
  justify-content: center;
}

.jc-r {
  justify-content: right;
}

.ai-c {
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.block {
  display: block;
}

/* Button */

.button {
  position: relative;
  background-color: transparent;
  color: #fff;
  border: 2px solid #31809d;
  padding: 13px 55px;
  height: 50px;
  width: 250px;
  margin-top: 50px;
  cursor: pointer;
  transition: ease 0.3s;
  font-family: "Outfit", sans-serif;
}

.button::before {
  content: "";
  width: 0;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #31809d;
  z-index: 2;
  transition: ease 0.5s;
}

.button_link {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  text-decoration: none;
  z-index: 5;
}

#light .button a:hover {
  color: #12121c;
  font-size: 400;
}

.button:hover {
  opacity: 0.8;
  transform: scale(1.05);
}

.button:hover::before {
  width: 100%;
}

@media screen and (max-width: 1100px) {
  .button:hover::before {
    width: 0;
  }
}
/* Loader */

.loader {
  height: 100vh;
  background-color: #12121c;
}

#light .loader {
  height: 100vh;
  background-color: #ffffff;
}

@keyframes animate-svg-fill {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

@-webkit-keyframes animate-svg-fill {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

@keyframes animate-svg-fill-light {
  0% {
    fill: transparent;
  }

  100% {
    fill: #12121c;
  }
}

@-webkit-keyframes animate-svg-fill-light {
  0% {
    fill: transparent;
  }

  100% {
    fill: #12121c;
  }
}

.svg-elem-1 {
  -webkit-animation: animate-svg-fill 0.3s cubic-bezier(0.47, 0, 0.745, 0.715)
    0.1s both;
  animation: animate-svg-fill 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.1s both;
}

#light .svg-elem-1 {
  -webkit-animation: animate-svg-fill-light 0.3s
    cubic-bezier(0.47, 0, 0.745, 0.715) 0.1s both;
  animation: animate-svg-fill-light 0.3s cubic-bezier(0.47, 0, 0.745, 0.715)
    0.1s both;
}

.svg-elem-2 {
  -webkit-animation: animate-svg-fill 0.3s cubic-bezier(0.47, 0, 0.745, 0.715)
    0.2s both;
  animation: animate-svg-fill 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.2s both;
}

#light .svg-elem-2 {
  -webkit-animation: animate-svg-fill-light 0.3s
    cubic-bezier(0.47, 0, 0.745, 0.715) 0.2s both;
  animation: animate-svg-fill-light 0.3s cubic-bezier(0.47, 0, 0.745, 0.715)
    0.2s both;
}

.svg-elem-3 {
  -webkit-animation: animate-svg-fill 0.3s cubic-bezier(0.47, 0, 0.745, 0.715)
    0.30000000000000004s both;
  animation: animate-svg-fill 0.3s cubic-bezier(0.47, 0, 0.745, 0.715)
    0.30000000000000004s both;
}

#light .svg-elem-3 {
  -webkit-animation: animate-svg-fill-light 0.3s
    cubic-bezier(0.47, 0, 0.745, 0.715) 0.30000000000000004s both;
  animation: animate-svg-fill-light 0.3s cubic-bezier(0.47, 0, 0.745, 0.715)
    0.30000000000000004s both;
}

.svg-elem-4 {
  -webkit-animation: animate-svg-fill 0.3s cubic-bezier(0.47, 0, 0.745, 0.715)
    0.4s both;
  animation: animate-svg-fill 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.4s both;
}

#light .svg-elem-4 {
  -webkit-animation: animate-svg-fill-light 0.3s
    cubic-bezier(0.47, 0, 0.745, 0.715) 0.4s both;
  animation: animate-svg-fill-light 0.3s cubic-bezier(0.47, 0, 0.745, 0.715)
    0.4s both;
}

.svg-elem-5 {
  -webkit-animation: animate-svg-fill 0.3s cubic-bezier(0.47, 0, 0.745, 0.715)
    0.5s both;
  animation: animate-svg-fill 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.5s both;
}

#light .svg-elem-5 {
  -webkit-animation: animate-svg-fill-light 0.3s
    cubic-bezier(0.47, 0, 0.745, 0.715) 0.5s both;
  animation: animate-svg-fill-light 0.3s cubic-bezier(0.47, 0, 0.745, 0.715)
    0.5s both;
}

.svg-elem-6 {
  -webkit-animation: animate-svg-fill 0.3s cubic-bezier(0.47, 0, 0.745, 0.715)
    0.6s both;
  animation: animate-svg-fill 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s both;
}

#light .svg-elem-6 {
  -webkit-animation: animate-svg-fill-light 0.3s
    cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s both;
  animation: animate-svg-fill-light 0.3s cubic-bezier(0.47, 0, 0.745, 0.715)
    0.6s both;
}

.svg-elem-7 {
  -webkit-animation: animate-svg-fill 0.3s cubic-bezier(0.47, 0, 0.745, 0.715)
    0.7000000000000001s both;
  animation: animate-svg-fill 0.3s cubic-bezier(0.47, 0, 0.745, 0.715)
    0.7000000000000001s both;
}

#light .svg-elem-7 {
  -webkit-animation: animate-svg-fill-light 0.3s
    cubic-bezier(0.47, 0, 0.745, 0.715) 0.7000000000000001s both;
  animation: animate-svg-fill-light 0.3s cubic-bezier(0.47, 0, 0.745, 0.715)
    0.7000000000000001s both;
}

.svg-elem-8 {
  -webkit-animation: animate-svg-fill 0.3s cubic-bezier(0.47, 0, 0.745, 0.715)
    0.8s both;
  animation: animate-svg-fill 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s both;
}

#light .svg-elem-8 {
  -webkit-animation: animate-svg-fill-light 0.3s
    cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s both;
  animation: animate-svg-fill-light 0.3s cubic-bezier(0.47, 0, 0.745, 0.715)
    0.8s both;
}

.svg-elem-9 {
  -webkit-animation: animate-svg-fill 0.3s cubic-bezier(0.47, 0, 0.745, 0.715)
    0.9s both;
  animation: animate-svg-fill 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s both;
}

#light .svg-elem-9 {
  -webkit-animation: animate-svg-fill-light 0.3s
    cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s both;
  animation: animate-svg-fill-light 0.3s cubic-bezier(0.47, 0, 0.745, 0.715)
    0.9s both;
}

.switch {
  position: relative;
  display: inline-block;
  margin-left: 30px;
}

.switch > span {
  position: absolute;
  top: 11px;
  pointer-events: none;
  font-family: "Helvetica", Arial, sans-serif;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  width: 50%;
  text-align: center;
}

input.check-toggle-round-flat:checked ~ .off {
  color: #2f3848;
}

input.check-toggle-round-flat:checked ~ .on {
  color: #fff;
}

.switch > span.on {
  left: 0;
  padding-left: 2px;
  color: #31809d;
}

.switch > span.off {
  right: 0;
  padding-right: 4px;
  color: #fff;
}

.check-toggle {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
}
.check-toggle + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input.check-toggle-round-flat + label {
  padding: 2px;
  width: 97px;
  height: 35px;
  background-color: #2f3848;
  -webkit-border-radius: 60px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  border-radius: 6px;
}
input.check-toggle-round-flat + label:before,
input.check-toggle-round-flat + label:after {
  display: block;
  position: absolute;
  content: "";
}

input.check-toggle-round-flat + label:before {
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  background-color: #2f3848;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  border-radius: 6px;
}
input.check-toggle-round-flat + label:after {
  top: 3px;
  left: 2px;
  bottom: 4px;
  right: 4px;
  width: 48px;
  background-color: #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  -webkit-transition: margin 0.2s;
  -moz-transition: margin 0.2s;
  -o-transition: margin 0.2s;
  transition: margin 0.2s;
}

input.check-toggle-round-flat:checked + label:after {
  margin-left: 44px;
  background-color: #31809d;
}

@media screen and (max-width: 1100px) {
  .switch {
    margin-bottom: 20px;
    margin-left: 0;
  }

  .logo--active img {
    width: 60px;
  }
}
