header {
  z-index: 99;
}

.header {
  position: fixed;
  background-color: #12121c;
  top: 0;
  left: 0;
  right: 0;
  height: 115px;
  max-height: 115px;
  padding: var(--padding-nav);
  transform: translateY(0);
  z-index: 99;
}

.nav {
  margin-right: 30px;
}

.opacity-anim {
  opacity: 1;
}

.nav_container {
  align-items: center;
}

.nav a {
  margin: 0 20px;
  text-decoration: none;
  transition: ease 0.3s;
}

.nav_toggles {
  display: flex;
  align-items: center;
}

.nav_icons {
  position: relative;
}

.nav_icons a {
  margin: 0 10px;
  padding: 5px;
  transition: ease 0.3s;
}

.nav_icons a:hover,
.nav a:hover {
  color: var(--accent-color);
}

.bulb {
  background-color: transparent;
  border: none;
  margin-left: 30px;
  cursor: pointer;
}

.bulb img {
  height: 25px;
  width: 100%;
}

.hamburger {
  display: none;
  padding: 10px;
}

.bar {
  width: 30px;
  height: 3px;
  background-color: var(--primary-color);
  display: block;
}

.bar:not(:last-child) {
  margin-bottom: 3px;
}

.hero {
  position: relative;
}

.hero_text {
  width: 100%;
  height: 100vh;
  text-align: center;
}

.hero_text h2 {
  position: relative;
}

.hand {
  position: absolute;
  transform-origin: bottom right;
  transition: transform 0.1s;
  margin-left: 10px;
  bottom: 5px;
}

.hero_mouse {
  position: absolute;
  bottom: 0;
  height: 13vh;
  left: 0;
  right: 0;
}

.mask_svg {
  position: absolute;
  z-index: 0;
  overflow: hidden;
  transform: translateY(-100%);
}

.layer_1 {
  position: absolute;
  top: 18%;
  right: -10%;
  -webkit-clip-path: url(#mask);
  clip-path: url(#mask);
  height: 500px;
  background-image: linear-gradient(
    var(--rotate),
    hsla(199, 100%, 50%, 1) 0%,
    #0008ff 100%
  );
  background: -moz-linear-gradient(
    var(--rotate),
    #00aeff 0%,
    hsla(238, 100%, 50%, 1) 100%
  );
  background: -webkit-linear-gradient(
    var(--rotate),
    hsla(199, 100%, 50%, 1) 0%,
    hsla(238, 100%, 50%, 1) 100%
  );
  width: 500px;
  z-index: 1;
  transform-origin: center;
  scale: 3;
  opacity: 0.4;
  animation: spin 2.5s linear infinite;
}

.layer_2 {
  position: absolute;
  top: 48%;
  left: -15%;
  -webkit-clip-path: url(#mask);
  clip-path: url(#mask);
  height: 500px;
  background-image: linear-gradient(
    var(--rotate),
    hsla(199, 100%, 50%, 1) 0%,
    #0008ff 100%
  );
  background: -moz-linear-gradient(
    var(--rotate),
    #00aeff 0%,
    hsla(238, 100%, 50%, 1) 100%
  );
  background: -webkit-linear-gradient(
    var(--rotate),
    hsla(199, 100%, 50%, 1) 0%,
    hsla(238, 100%, 50%, 1) 100%
  );
  width: 500px;
  z-index: 1;
  transform-origin: center;
  scale: 1.1;
  opacity: 0.4;
  animation: spin 2.5s linear infinite;
}

.layer_3 {
  position: absolute;
  top: 81%;
  right: -35%;
  -webkit-clip-path: url(#mask);
  clip-path: url(#mask);
  height: 500px;
  background-image: linear-gradient(
    var(--rotate),
    hsla(199, 100%, 50%, 1) 0%,
    #0008ff 100%
  );
  background: -moz-linear-gradient(
    var(--rotate),
    #00aeff 0%,
    hsla(238, 100%, 50%, 1) 100%
  );
  background: -webkit-linear-gradient(
    var(--rotate),
    hsla(199, 100%, 50%, 1) 0%,
    hsla(238, 100%, 50%, 1) 100%
  );
  width: 500px;
  z-index: 1;
  transform-origin: center;
  scale: 2;
  opacity: 0.4;
  animation: spin 2.5s linear infinite;
}

/* Home project's section */

.projects_section {
  overflow: hidden;
  margin: 50px 0;
}

.projects_sectionButton {
  display: flex;
  justify-content: center;
  padding: 30px 0;
}

.projects_section button {
  height: 50px;
  width: 350px;
  opacity: 0;
}

.title {
  overflow: hidden;
  line-height: 1;
}

.title-anim {
  display: block;
  transform: translate(0, 100%);
  transition: transform 0.5s;
}
.text {
  overflow: hidden;
  line-height: 1;
}

.text-anim {
  display: block;
  transform: translate(0, 100%);
  transition: transform 0.5s;
}

/* Project component */

.project {
  transition: ease 0.4s;
  width: 100%;
  opacity: 0;
  transition: opacity ease 0.8s;
}

.project_imgContainer {
  object-fit: cover;
  max-height: 600px;
  position: relative;
}

.project_imgContainer img {
  width: 100%;
  border: 3px solid #31809d;
  z-index: 1;
}

.project_imgOverlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #222233e7;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: ease 0.3s;
}

#light .project_imgOverlay {
  background-color: rgba(255, 255, 255, 0.858);
}

.project_imgContainer:hover .project_imgOverlay {
  opacity: 1;
}

.project_imgOverlay .button {
  opacity: 1;
}

.project_textContainer {
  padding: 10px 5px;
}

.project_textContainer h4 {
  width: fit-content;
  font-weight: 600;
  color: var(--primary-color);
  margin-bottom: 5px;
}

.project_tech {
  padding: 20px 0;
  flex-wrap: wrap;
  row-gap: 10px;
}

.project_tech span {
  margin-right: 10px;
  padding: 5px 15px;
  background-color: var(--secondary-color);
  border-radius: 2px;
}

#light .project_tech span {
  color: #fff;
}

.project h4 {
  margin-top: 15px;
}

.projects {
  margin-top: 100px;
  gap: 30px;
  grid-template-columns: repeat(auto-fill, minmax(550px, 1fr));
}

/* About me section */

.about {
  margin: 150px 0;
}

.about img {
  height: 70vh;
  width: 35vw;
  object-fit: cover;
}

.about_text {
  margin-left: 100px;
}

.about_text h2 {
  margin-bottom: 35px;
}

.about_text p {
  line-height: 130%;
}

.about_text button {
  width: 350px;
}

/* Skills section Homepage */

.skills {
  position: relative;
  margin: 250px 0;
}

.skills h2 {
  text-align: center;
}

#light .skill h4 {
  color: var(--secondary-color);
  font-size: 600;
}

.skills_list {
  margin: 0 auto;
  margin-top: 100px;
  gap: 30px;
}

.skill {
  margin: 0 50px;
  margin-bottom: 30px;
}

.skill h4 {
  margin-top: 15px;
}

.skill img {
  width: 70px;
  height: auto;
}

/* Contact */

.contact {
  margin: 150px 0;
  width: 100%;
}

.contact_inputs {
  width: 100%;
}

.contact_inputs div {
  flex: 1;
}

.contact > form {
  margin-top: 100px;
}

.contact > form p {
  margin-bottom: 20px;
}

.contact > form label {
  display: flex;
  flex-direction: column;
  color: var(--accent-color2);
  margin-bottom: 10px;
}

.contact > form input,
.contact > form select,
.contact > form textarea {
  width: 40vw;
  height: 40px;
  border-radius: 5px;
  margin-top: 10px;
  background-color: transparent;
  border: 2px #a1a1a158 solid;
  color: var(--primary-color);
  font-family: "Outfit", sans-serif;
  padding: 0 15px;
  font-size: var(--xxs);
}

#light .contact > form label {
  color: var(--secondary-color);
}

#light .contact > form input,
#light .contact > form select,
#light .contact > form textarea {
  border: 2px #2f3848 solid;
  color: var(--secondary-color);
  font-weight: 500;
}

.contact > form input:focus {
  border-color: #a1a1a1;
  outline: none;
}

.form_message {
  margin-left: 50px;
}

.contact > form textarea {
  width: 100%;
  height: 190px;
  padding: 20px;
}

.contact > form option {
  background-color: #12121c;
}

.contact > form input:-webkit-autofill,
.contact form input:autofill {
  border: 3px solid blue;
  background-color: #31809d17;
  outline: none;
}

.contact > form option:hover {
  background-color: #31809d57;
}

.contact form button span {
  color: #fff;
}

.contact .red_label,
#light .contact .red_label {
  color: rgb(255, 0, 0);
  font-weight: 500;
}

#light .contact form button span {
  color: #12121c;
  font-weight: 500;
}

/* Footer */

.footer {
  background-color: #222233;
  padding: var(--padding-nav);
  padding-top: 50px;
  padding-bottom: 70px;
}

#light .footer {
  background-color: var(--secondary-color);
  color: #fff;
}

#light .footer_icons a {
  color: #fff;
}

#light .footer_icons a:hover {
  color: var(--accent-color);
}

.footer_copySocial {
  align-items: flex-end;
  margin-top: 30px;
}

/* ---------------------- */

/* Portfolio page */

.portfolio_hero {
  padding: var(--padding-regular);
  padding-top: 285px;
  padding-bottom: 10px;
}

#light .portfolio_hero p {
  color: #12121cab;
  font-weight: 400;
}

.portfolio_projectsSection {
  padding: var(--padding-regular);
  padding-top: 0;
}

/* Submission page */

.submission {
  height: 100vh;
  width: 100%;
  background-color: var(--bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.submission_container {
  width: 60%;
  height: 200px;
  background-color: #fff;
  color: #12121c;
  padding: 2rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
}

.submission_container h1 {
  font-size: 2rem;
}

.submission_container p {
  color: #12121cbb;
  font-weight: 400;
}

.submission_button {
  margin-top: 25px;
  width: 300px;
  background-color: transparent;
  border: none;
  text-align: left;
  padding: 0;
}

.submission_button a {
  color: #31809d;
  font-weight: 500;
}

.submission_icon {
  margin-right: 15px;
  color: #31809d;
}

/* ---------- */

@media screen and (max-width: 87.5em) {
  .layer_1 {
    scale: 2.8;
    top: 18%;
    right: -20%;
  }
}

.char {
  transform: translateY(100%);
  transition: transform 0.5s;
}

.line {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  line-height: 5.2rem;
}

/* 1100px */

@media screen and (max-width: 68.75em) {
  .header {
    height: 90px;
    transition: ease 0.3s;
    transform: translateY(0);
  }

  .opacity-anim {
    opacity: 1;
  }

  .nav_container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 100;
    background-color: #12121c;
    flex-direction: column;
    justify-content: space-evenly;
    transform: translateX(100%);
    transition: transform 0.3s;
    -webkit-transition: transform 0.3s;
    -webkit-transition: -webkit-transform 0.3s;
    padding: 150px 0;
  }

  #light .nav_container {
    background-color: #fff;
  }

  .nav {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 0;
    transition: ease 0.3s;
  }

  .nav a {
    margin: 30px 0;
    font-weight: 700;
    line-height: auto;
    font-size: 2.6rem;
  }

  .nav_icons {
    height: 10vh;
    display: flex;
    align-items: center;
  }

  .nav_icons a {
    transform: scale(1.4);
  }

  .opacity-social {
    opacity: 0;
  }

  .bulb {
    transform: scale(1.6);
    margin-left: 0;
  }

  .hamburger {
    display: block;
    position: absolute;
    right: 45px;
    z-index: 399;
  }

  .hamburger .bar {
    transition: ease 0.3s;
    transform-origin: 11px 1px;
  }

  .hamburger--active .bar {
    transform-origin: 11px 1px;
  }

  .hamburger--active .bar:first-child {
    transform: rotate(45deg);
  }

  .hamburger--active .bar:last-child {
    transform: rotate(-45deg);
  }

  .layer_1 {
    scale: 3.2;
    top: 16.8%;
    right: -45%;
  }

  .layer_2 {
    top: 52%;
    left: -25%;
  }

  .hero_text h2 {
    margin: 25px 0;
  }

  .project {
    margin-bottom: 40px;
    max-width: 100%;
  }

  .project_imgContainer {
    display: flex;
    flex-direction: column-reverse;
    max-height: 700px;
  }

  .project_imgOverlay {
    position: relative;
    opacity: 1;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    width: 90%;
    margin: 0 auto;
    margin-top: 50px;
  }

  .project_imgOverlay .button {
    margin-top: 0;
    width: 100%;
  }

  .projects {
    flex-direction: column;
  }

  .about {
    flex-direction: column;
  }

  .about img {
    width: 100%;
    height: 50vh;
  }

  .about_text {
    margin-left: 0;
    margin-top: 50px;
    padding: 0 25px;
  }

  .about_text button {
    margin: 75px auto 0 auto;
  }

  .skills {
    margin: 200px 0;
  }

  .contact form {
    width: 100%;
  }

  .contact_inputs {
    display: block;
  }

  .form_message {
    margin-left: 0;
  }

  .contact > form input,
  .contact > form select,
  .contact > form textarea {
    width: 100%;
  }

  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer_copySocial {
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
  }

  .footer_icons {
    margin-top: 50px;
  }

  .portfolio_hero {
    padding: var(--padding-regular);
    padding-top: 155px;
    padding-bottom: 50px;
  }

  /*  Submission Page */

  .submission_container {
    width: 85%;
    height: 350px;
    align-items: center;
  }
}

/* 600px */

@media screen and (max-width: 37.5em) {
  .nav_container {
    padding: 150px 20px;
    align-items: flex-start;
  }

  .nav {
    margin: 10px 0;
    align-items: flex-start;
  }

  .nav a {
    font-size: 2rem;
    line-height: 3rem;
    text-align: left;
    margin: 20px 0;
    font-weight: 700;
  }

  .nav_icons {
    height: 20vh;
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  .nav_icons a:first-child {
    margin-left: 0;
  }

  .nav_toggles {
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    margin-top: 60px;
    width: 100%;
  }

  .hamburger {
    right: 25px;
  }

  .switch {
    margin: 0;
    margin-right: 50px;
  }

  .bulb {
    transform: scale(1.4);
  }

  .hero {
    padding: 0 8px;
  }

  .line {
    line-height: 3.5rem;
  }

  .hero_text h1 {
    margin: 15px 0;
  }

  .hand {
    position: absolute;
    transform-origin: bottom right;
    transition: transform 0.1s;
    margin-left: 10px;
    bottom: 0px;
    right: -30px;
  }

  .projects {
    margin-top: 50px;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }

  .project {
    margin-bottom: 0px;
  }

  .project_imgContainer {
    object-fit: cover;
    max-height: 600px;
    position: relative;
  }

  .project_imgOverlay {
    position: relative;
    opacity: 1;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-bottom: 10px;
  }

  .projects_section button {
    width: 100%;
    margin-top: 20px;
  }

  .about {
    margin: 100px 0;
  }

  .skills {
    margin: 50px 0;
    padding: 50px 0;
  }

  .skill {
    margin: 20px 20px;
  }

  .about_text {
    padding: 0 15px;
  }

  #light .about_text p {
    color: hsla(218, 21%, 23%, 0.713);
  }

  .contact {
    margin: 50px 0 100px 0;
  }

  .contact > form {
    margin-top: 70px;
  }

  .mask_svg {
    display: none;
  }

  .layer_1 {
    position: absolute;
    top: 10%;
    right: -60%;
    background-image: url(../public/circle-01-bg.svg);
    background-repeat: no-repeat;
    background-size: contain;
    animation: none;
    -webkit-clip-path: none;
    clip-path: none;
    scale: 1.4;
  }

  .layer_2 {
    position: absolute;
    top: 38.4%;
    left: -65%;
    background-image: url(../public/circle-01-bg.svg);
    background-repeat: no-repeat;
    background-size: contain;
    animation: none;
    -webkit-clip-path: none;
    clip-path: none;
    scale: 0.8;
  }

  .layer_3 {
    position: absolute;
    top: 80.6%;
    right: -70%;
    background-image: url(../public/circle-01-bg.svg);
    background-repeat: no-repeat;
    background-size: contain;
    animation: none;
    -webkit-clip-path: none;
    clip-path: none;
    scale: 1;
  }

  /* Submission page */

  .submission_container {
    width: 90%;
    height: 500px;
    align-items: center;
    padding: 2rem 2rem;
    text-align: center;
  }

  .submission_container h1 {
    margin-bottom: 10px;
  }

  .submission_button {
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 50px;
  }

  .submission_button a {
    text-align: center;
    justify-content: center;
  }
}

@keyframes spin {
  0% {
    --rotate: 0deg;
  }
  100% {
    --rotate: 360deg;
  }
}
